.resa-wp.home{
  background-color: $wh-a;
  padding: px(20) px(60) px(30) px(30);
  border-left: 10px solid $bl-a;
  box-shadow: 2px 5px 5px rgba(0,0,0,0.1);
  .title {
    font-size: px(30);
    font-weight: 500;
  }
  .label {
    font-size: px(13);
    color: $gy-c;
    margin-bottom: 0;
  }
  @include media-breakpoint-down(md) {
    .title {
      font-size: px(27);
    }
  }
  @include media-breakpoint-down(xs) {
    padding: px(20);
    .title {
      font-size: px(24);
    }
  }
}

.home-top {
  position: relative;
  height: px(600);
  padding-top: px(60);
  background-image: url("../images/illustrations/home-bg.jpg");
  background-size: cover;
  background-position: center;

  &:after {
    content: "";
    width: 2500px;
    height: 605px;
    z-index: 2;
    position: absolute;
    bottom: px(-6);
    left: 50%;
    margin-left: px(-1250);
    background-image: url("../images/illustrations/home-mask.png");
  }

  .img-car {
    position: absolute;
    z-index: 3;
    width: px(700);
    right: px(-120);
    bottom: px(-150);
  }
  @include media-breakpoint-down(lg) {
    &:after {
      margin-left: px(-1400);
    }

    .img-car {
      right: px(-100);
      bottom: px(-100);
    }
  }
  @include media-breakpoint-down(md) {
    &:after {
      margin-left: px(-1500);
    }

    .img-car {
      width: px(650);
      right: px(-100);
      bottom: px(-100);
    }
  }
  @include media-breakpoint-down(sm) {
    height: auto;
    padding: px(50) 0;
    &:after {
      display: none;
    }

    .img-car {
      display: none;
    }
  }
  @include media-breakpoint-down(xs) {
    padding: px(30) px(15);
  }
}

.engagement-wp {
  position: relative;

  .img-left {
    position: absolute;
    width: 300px;
    left: -100px;
    top: 0;
  }

  .img-right {
    position: absolute;
    width: px(450);
    right: -200px;
    top: px(-200);
    @include media-breakpoint-down(lg) {
      top: px(-350);
      z-index: 2;
    }
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .list {
    text-align: center;

    .item {
      vertical-align: top;
      display: inline-block;
      text-align: center;
      margin: 0 px(20);

      .title {
        line-height: 1.3em;
        margin-top: px(15);
        font-weight: 700;
        text-transform: uppercase;
        font-size: px(15);
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .img-left {
      display: none;
    }
  }
  @include media-breakpoint-down(md) {
    .list {
      .item {
        margin: 0;

        .sprite {
          transform: scale(0.8);
        }

        .title {
          margin-top: px(5);
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .list {
      .item {
        margin: 0 px(30);

        .sprite {
          transform: scale(0.8);
        }

        .title {
          font-size: px(14);
          margin-top: px(5);
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .list {
      .item {
        display: block;

        .sprite {
          transform: scale(1);
        }

        &:not(:last-child) {
          margin-bottom: px(20);
        }
      }
    }
  }
}


.slick-slide {
	outline: none !important;
}

.slick-vertical .slick-slide {
	border: none !important;
}

.slick-dots {
	margin-top: px(15);
	text-align: center;

	li {
		display: inline-block;
		vertical-align: top;
		margin: 0 2px;
	}

	button {
		width: 14px;
		height: 14px;
		margin-right: 5px;
		background-color: $bk-a;
		border-radius: px(18);
		transition: all 0.2s ease-in-out;
		overflow: hidden;
		text-indent: -999em;
	}

	.slick-active button {
		background-color: $bk-a;
	}

	button:focus,
	button:hover {
		background-color: $bk-a;
	}
}

#slick-home-wp {
  padding: 0 px(80);
  position: relative;

  #slick-home-arrows {
    .arrow-next,
    .arrow-prev {
      position: absolute;
      top: 50%;
      margin-top: px(-35);
      transition: all 0.2s ease-in-out;

      &:focus,
      &:hover {
        transform: scale(0.9);
      }
    }

    .arrow-prev {
      left: 0;
    }

    .arrow-next {
      right: 0;
    }
  }

  .item {
    padding: px(15);
  }
  @include media-breakpoint-down(xs) {
    padding: 0 px(40);

    #slick-home-arrows {
      .arrow-next,
      .arrow-prev {
        transform: scale(0.5);

        &:focus,
        &:hover {
          transform: scale(0.6);
        }
      }

      .arrow-prev {
        left: px(-15);
      }

      .arrow-next {
        right: px(-15);
      }
    }
  }
}

.product-home {
  height: 100%;

  .link {
    display: block;
    position: relative;
    background-color: $wh-a;
    border: 1px solid $gy-c;
    box-shadow: 0 5px 5px rgba(0,0,0,0);
  }

  .content {
    padding: px(20) px(20) px(50);
  }

  .title {
    @extend .h-like-b;
  }

  .title-complementary {
    font-size: px(14);
    font-weight: 500;
    margin-bottom: 0;
  }

  .btn-wp {
    position: absolute;
    width: 100%;
    text-align: center;
    left: 0;
    bottom: px(20);

    .btn-a {
      display: inline-block;
    }
  }

  .link {
    &:focus,
    &:hover {
      border-color: $bl-a;
      box-shadow: 0 5px 5px rgba(0,0,0,0.1);

      .btn-a {
        background-color: $ye-b;
        color: $bk-a;
      }
    }
  }

  .car-icon {
    display: flex;
    align-items: center;
    font-size: px(13);
    color: shade($gy-c, 60%);
    margin-top: 2px;
  }

  .car-icon .txt {
    white-space: nowrap;
    padding-left: px(3);
  }
}

.about-wp {
  background-color: $gy-a;
  padding: px(100) 0;
  position: relative;

  .img-left,
  .img-right {
    position: absolute;
  }

  .img-left {
    left: 50%;
    margin-left: px(-850);
    top: 50%;
    margin-top: px(-325);
    width: px(800);
  }

  .img-right {
    right: 0;
    bottom: px(40);
    width: px(150);
  }
  @include media-breakpoint-down(lg) {
    padding: px(75) 0;
  }
  @include media-breakpoint-down(md) {
    .img-left {
      margin-left: px(-950);
    }
  }
  @include media-breakpoint-down(sm) {
    padding: px(50) 0;

    .img-left,
    .img-right {
      display: none;
    }
  }
  @include media-breakpoint-down(sm) {
    padding: px(30) 0;
  }
}
