// Header :: Fixed or small/large

body {
	padding-top: 0;
}

.anchor-scroll {
	display: block;
	margin-top: 0;
	position: absolute;
	width: 100%;
	height: 1px;
	background-color: transparent;
}
@include media-breakpoint-down(lg) {
	body {
		padding-top: 0;
	}

	.anchor-scroll {
		margin-top: 0;
	}
}
// Header :: Small on scroll

body.header-small #header {}
@include media-breakpoint-up(lg) {
	body.header-small #header {}
}
// Navigation :: Force Display on large device
@include media-breakpoint-up(sm) {
	#nav-main {
		display: flex !important;
	}

	#nav-mobile-trigger {
		display: none;
	}
}
// Header :: Structure

#header {
	position: relative;
	z-index: 10;
	width: 100%;
	padding: 0;
	background-color: $wh-a;
	transition: all 0.2s ease-in-out;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
	@include media-breakpoint-down(xs) {
		.logo {
			width: px(175);
			display: block;
		}

		#nav-mobile-trigger {
			background-color: $ye-b;
			width: px(50);
			height: px(50);
			position: absolute;
			top: px(3);
			right: px(15);
		}
	}
}
// Navigation :: Main

#nav-main {
	display: flex;
	align-items: center;

	.item {
		flex-grow: 1;

		&.except {
			padding-left: px(30);
		}
		@include media-breakpoint-down(lg) {
			&.except {
				padding-left: px(10);
			}
		}
		@include media-breakpoint-down(md) {
			&.except {
				padding-left: px(30);
			}
		}
		@include media-breakpoint-down(xs) {
			&.except {
				padding-left: 0;
			}
		}
	}

	.link {
		display: block;
		color: $bk-a;
		font-weight: 500;
		font-size: px(14);
		padding: px(10) px(10) 0;
		text-align: center;
	}

	.link:focus,
	.link:hover {
		text-decoration: underline;
	}

	.link.active {
		color: $ye-b;
	}

	.btn-a {
		display: flex;
		padding: px(9) px(10) px(10);
		justify-content: center;
		align-items: center;

		.material-icons {
			padding-top: px(2.5);
			margin-right: px(7.5);
			font-size: px(18);
		}
	}
	@include media-breakpoint-down(md) {
		.link {
			font-size: px(13);
			padding-left: 0;
			padding-right: 0;
		}
	}
	@include media-breakpoint-down(xs) {
		background-color: tint($gy-a, 50%);
		padding: px(10) px(20) px(20);
		display: none;

		.item {
			text-align: center;
		}

		.link {
			font-size: px(14);
		}

		.btn-a {
			width: px(150);
			margin: px(15) auto 0;
		}
	}
}

#header-inner {
	background-color: $bl-b;
	padding: px(35) 0;
	text-align: center;
	background: -moz-linear-gradient(top, rgba(33,76,111,1) 0%, rgba(33,76,111,0.9) 99%, rgba(33,76,111,0.9) 100%);
	background: -webkit-linear-gradient(top, rgba(33,76,111,1) 0%,rgba(33,76,111,0.9) 99%,rgba(33,76,111,0.9) 100%);
	background: linear-gradient(to bottom, rgba(33,76,111,1) 0%,rgba(33,76,111,0.9) 99%,rgba(33,76,111,0.9) 100%);
	.title {
		text-transform: uppercase;
		font-size: px(30);
		letter-spacing: 0.05em;
		font-weight: 500;
		color: $wh-a;
	}
	.icon-wp {
		display: block;
		border-radius: 100%;
		text-align: center;
		color: $bl-b;
		width: px(34);
		height: px(34);
		margin: 0 auto px(10);
		padding-top: px(6);
		background-color: $wh-a;
		.material-icons {
			font-size: px(22);
			line-height: 1em;
		}
	}
	@include media-breakpoint-down(lg) {
		.title {
			font-size: px(28);
		}
	}
	@include media-breakpoint-down(md) {
		padding: px(30) 0;
		.title {
			font-size: px(26);
		}
	}
	@include media-breakpoint-down(sm) {
		padding: px(25) 0;
		.title {
			font-size: px(22);
		}
		.icon-wp {
			transform: scale(0.9);
			margin-bottom: px(5);
		}
	}
	@include media-breakpoint-down(xs) {
		padding: px(20) 0;
		.title {
			font-size: px(20);
		}
		.icon-wp {
			transform: scale(0.7);
			margin-bottom: px(5);
		}
		.btn-a {
			padding: px(8) px(20);
			font-size: px(12);
		}
	}
}
