// Custom variables :: Colors
//
// -> bk : black;
// -> gy : grey;
// -> wh : white;
// -> pu : purple;
// -> bl : blue;
// -> br : brown;
// -> or : orange;
// -> gn : green;
// -> pk : pink;
$bk-a: #000000;
$wh-a: #FFFFFF;
$bl-a: #3f91d2;
$bl-b: #214C6F;
$ye-a: #fde49a;
$ye-b: #f9bb03;
$gy-a: #F7F7F7;
$gy-b: #bbbcbd;
$gy-c: #9B9B9B;

.c-bk-a {
  color: $bk-a;
}

.c-wh-a {
  color: $wh-a;
}

.c-gy-a {
  color: $gy-a;
}
.c-gy-b {
  color: $gy-b;
}
.c-gy-c {
  color: $gy-c;
}

.c-ye-a {
  color: $ye-a;
}

.c-ye-b {
  color: $ye-b;
}

.c-bl-a {
  color: $bl-a;
}

.c-bl-b {
  color: $bl-b;
}

// Background
.bg-bk-a {
  background-color: $bk-a;
}

.bg-wh-a {
  background-color: $wh-a;
}

.bg-ye-a {
  background-color: $ye-a;
}

.bg-ye-b {
  background-color: $ye-b;
}

.bg-bl-a {
  background-color: $bl-a;
}

.bg-bl-b {
  background-color: $bl-b;
}
.bg-gy-a {
  background-color: $gy-a;
}
.bg-gy-b {
  background-color: $gy-b;
}
.bg-gy-c {
  background-color: $gy-c;
}
