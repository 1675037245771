.map-wrapper {
	position: relative;
	height: 300px;
	background-color: $bl-b;

	.map-canvas {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
	@include media-breakpoint-down(md) {
		height: 250px;
	}
	@include media-breakpoint-down(sm) {
		height: 200px;
	}
}

.item-contact {


	.list {
		margin-top: 8px;
		padding-left: 20px;
		li {
			position: relative;
		}
	}


	.list li:not(:last-child) {
		margin-bottom: 7px;
	}

	.list li:before {
		content: "";
		position: absolute;
		top: 9px;
		left: px(-20);
		width: 8px;
		height: 2px;
		background-color: $bl-b;
	}
	@include media-breakpoint-down(sm) {
	display: inline-block;
		width: 49.5%;
		vertical-align: top;
	}
	@include media-breakpoint-down(xs) {
		float: block;
		width: 100%;
	}
}
