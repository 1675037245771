// Footer

#footer {
	&:before {
		content: "";
		width: 760px;
		height: 301px;
		background-image: url("../images/illustrations/bg-footer.png");
		display: block;
		margin: 0 auto;
	}
	background-color: $wh-a;
	color: rgba(255,255,255,0.85);
	font-size: px(13);
	.bg-bl-b {
		font-size: px(11);
	}
	.link {
		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}
	.sprite {
		transform: scale(0.8);
		transition: all 0.2s ease-in-out;
		&:hover,
		&:focus {
			transform: scale(0.9);
		}
	}

	.title {
		font-size: px(17);
		font-weight: 400;
	}
	@include media-breakpoint-down(lg) {}
	@include media-breakpoint-down(md) {}
	@include media-breakpoint-down(sm) {
		&:before {
			display: none;
		}
	}
}
