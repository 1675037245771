// Sprite :: Structure

.sprite {
	display: inline-block;
	background-image: url("../images/icons/sprite.png");
	background-repeat: no-repeat;
	vertical-align: text-top;
	background-size: 1100px;
}

.sprite.back-top {
	width: 50px;
	height: 50px;
}

.sprite.back-top {
	background-position: 0 0;
}

.sprite {
	&.arrow-select {
		width: 10px;
		height: 10px;
		background-position: -930px 0;
	}
	&.icon-1,
	&.icon-2,
	&.icon-3,
	&.icon-4 {
		width: 140px;
		height: 140px;
	}
	&.icon-1 {
		background-position: -60px 0;
	}
	&.icon-2 {
		background-position: -200px 0;
	}
	&.icon-3 {
		background-position: -340px 0;
	}
	&.icon-4 {
		background-position: -480px 0;
	}
	&.prev,
	&.next {
		width: 70px;
		height: 70px;
	}
	&.prev {
		background-position: -620px 0;
	}
	&.next {
		background-position: -690px 0;
	}
	&.facebook {
		width: 20px;
		height: 40px;
		background-position: -760px 0;
	}
	&.car-box,
	&.car-clim,
	&.car-door,
	&.car-gazoil,
	&.car-luggage,
	&.car-people {
		width: 20px;
		height: 20px;
	}

	&.car-gazoil {
		background-position: -800px 0;
	}

	&.car-clim {
		background-position: -820px 0;
	}

	&.car-box {
		background-position: -840px 0;
	}

	&.car-door {
		background-position: -860px 0;
	}

	&.car-people {
		background-position: -880px 0;
	}

	&.car-luggage {
		background-position: -900px 0;
	}

}
