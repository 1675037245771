.blog-category {
  background-color: $bl-b;
  display: inline-block;
  color: $wh-a;
  line-height: 1em;
  padding: px(6) px(10) px(5);
  text-transform: uppercase;
  font-size: px(10);
  border-radius: 10px;
  margin-bottom: px(10);
  &.large {
    font-size: px(14);
    border-radius: 50px;
    padding: px(10) px(20) px(9);
    margin-bottom: px(20);
    @include media-breakpoint-down(xs) {
      font-size: px(12);
      padding: px(8) px(20) px(7);
      margin-bottom: px(10);
    }
  }
}

.blog-item {
  height: 100%;

  .link {
    height: 100%;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    border: 1px solid $gy-c;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 5px 5px rgba(0,0,0,0);
  }

  .col-top {
    .content {
      padding: px(20) px(25) px(10);
    }
  }

  .col-bottom {
    .content {
      padding: px(0) px(25) px(30);
    }
  }

  .img-wp {
    overflow: hidden;

    img {
      transition: all 0.2s ease-in-out;
    }
  }

  .date {
    color: $gy-c;
    font-weight: 500;
    font-size: px(15);
  }

  .title {
    @extend .h-like-b;
    margin-top: px(20);
    transition: all 0.2s ease-in-out;
  }

  .desc {
    color: $gy-c;
    font-weight: 500;
    font-size: px(15);
    margin-top: px(15);
  }

  .btn-more {
    color: $bl-a;
    font-weight: 500;
    font-size: px(16);
  }

  &:focus,
  &:hover {
    .link {
      border-color: $bl-a;
      box-shadow: 0 5px 5px rgba(0,0,0,0.1);
    }

    .img-wp {
      img {
        transform: scale(1.1);
      }
    }

    .title {
      color: $bl-a;
    }
  }
  @include media-breakpoint-down(xs) {
    .col-top {
      .content {
        padding: px(20) px(20) px(0);
      }
    }
    .col-bottom {
      .content {
        padding: px(0) px(20) px(20);
      }
    }

    .desc,
    .title {
      margin-top: px(10);
    }
  }
}

.blog-navigation{
  border: 1px solid $gy-a;
  padding: px(20) px(30);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  background-color: $wh-a;
  border-radius: 8px;

  .title-cat {
    font-size: px(13);
    padding-bottom: 6px;
    color: $bl-b;
    margin-bottom: px(15);
    text-transform: uppercase;
    font-weight: 600;
    border-bottom: 1px solid shade($gy-b, 50%);
  }

  .item {
    margin-top: px(5);
  }

  .list .item:first-child {
    margin-top: 0;
  }

  .link {
    position: relative;
    font-weight: 400;
    font-size: px(14);
    color: shade($gy-b, 50%);
    display: block;
    padding-left: px(20);
  }

  .link:before {
    content: "";
    display: block;
    position: absolute;
    top: 10px;
    left: 0;
    width: 8px;
    height: 2px;
    background-color: shade($gy-b, 50%);
    transition: all 0.2s ease-in-out;
  }

  .link.active {
    color: $bl-b !important;
    font-weight: 600;
  }

  .link.active:before {
    border-color: $bl-b !important;
    background-color: $bl-b !important;
  }

  .link:focus,
  .link:hover {
    color: $bl-b;
  }

  .link:focus:before,
  .link:hover:before {
    background-color: $bl-b;
  }
  @include media-breakpoint-down(sm) {
    border: none;
    box-shadow: 0 0 20px rgba(0,0,0,0.1);
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.pagination {
	justify-content: center;
	margin-top: 0;

	.page-link {
		color: $bl-b;
		height: 35px;
    line-height: 1.4em;
	}

	.page-link.active {
		background-color: $bl-b !important;
		color: $wh-a !important;
		border-top-color: $bl-b;
		border-bottom-color: $bl-b;
		font-weight: 400;
	}

	.page-item.link-arrow .page-link {
		padding: px(6) px(12);
		background-color: tint($gy-a, 30%);
		color: $gy-b;
		transition: all 0.2s ease-in-out;
	}

	.page-item.link-arrow .page-link:focus,
	.page-item.link-arrow .page-link:hover {
		background-color: $bl-b;
		color: $wh-a;
	}

	.page-item .page-link:focus,
	.page-item .page-link:hover {
		background-color: tint($gy-a, 90%);
	}
}

.blog-article {
  > *:first-child {
    margin-top: 0 !important;
  }

  > *:last-child {
    margin-bottom: 0 !important;
  }

  h2 {
    font-size: 27px;
    font-weight: 600;
    color: $bl-b;
  }

  h3 {
    font-size: 23px;
    font-weight: 600;
    color: $bl-b;
  }

  .headline {
    font-size: 17px;
    font-weight: 600;
  }

  p {
    font-size: 15px;
    margin-bottom: px(15);
  }

  .highlight-wp {
    margin-bottom: 0;
    color: $bl-b;
    font-size: 16px;
    font-weight: 600;
    font-style: italic;
    border: 2px solid $bl-b;
    padding: 30px;
    margin: 50px auto;
    width: 75%;
    display: block;
  }

  ol,
  ul {
    padding-left: 30px;
  }

  ol li,
  ul li {
    display: block;
    position: relative;
    padding-left: 22px;
    margin-bottom: 10px;
  }

  ul li {
    font-weight: 400;
    font-size: 15px;
  }

  ul li:before {
    content: "";
    @include position(absolute, 12px null null 3px);
    width: 8px;
    height: 2px;
    background-color: $bl-b;
  }

  ol {
    counter-reset: ol-counter;
  }

  ol li {
    position: relative;
    display: block;
    line-height: 1.4em;
    font-size: 15px;
    font-weight: 400;
  }

  ol li:before {
    content: counter(ol-counter)".";
    color: $bl-b;
    counter-increment: ol-counter;
    font-size: px(14);
    font-weight: 600;
    position: absolute;
    top: 0;
    left: 0;
  }

  ol li:last-child {
    margin-bottom: 0;
  }

  h2,
  h3,
  ol,
  ul {
    margin: 30px 0 35px;
  }

  a {
    color: $bl-b;
    text-decoration: underline;
  }

  .btn-wp {
    margin: 30px 0;
  }

  .btn-wp a {
    @extend .btn-a;
    @extend .yellow;
    text-decoration: none;
    padding-right: px(20);
  }

  img {
    max-width: 100%;
    height: auto;
  }

  img.img-left {
    float: left;
    margin: 5px 30px 15px 0;
  }

  img.img-right {
    float: right;
    margin: 5px 0 15px 30px;
  }

  img.img-center {
    margin: 50px auto;
    display: block;
  }

  table {
    @extend .table;
    @extend .table-striped;
    @extend .table-bordered;
  }
  @include media-breakpoint-down(sm) {
    h2 {
      font-size: 25px;
    }

    h3 {
      font-size: 21px;
    }

    .headline {
      font-size: 16px;
    }

    .highlight-wp {
      width: 100%;
    }

    img.img-left,
    img.img-right {
      float: none;
      margin: 30px 0 !important;
      display: block;
    }

    .btn-wp a {
      padding-right: px(15);
    }
  }
  @include media-breakpoint-down(xs) {
    h2 {
      font-size: 22px;
    }

    h3 {
      font-size: 19px;
    }

    .headline {
      font-size: 15px;
    }

    li,
    ol li:before,
    p {
      font-size: 14px !important;
    }

    .highlight-wp {
      width: 100%;
    }

    ol,
    ul {
      padding-left: 0;
    }

    img,
    img.img-center {
      float: none;
      margin: 20px 0 !important;
      display: block;
    }

    h2,
    h3,
    ol,
    ul {
      margin: 20px 0;
    }

    .highlight-wp {
      margin: 20px 0;
      padding: 20px;
    }
  }
}
@include media-breakpoint-down(xs) {
  body .article-wp > *:first-child {
    margin-top: 0 !important;
  }

  body .article-wp > *:last-child {
    margin-bottom: 0 !important;
  }
}

#modal-content {
  .article-wp h2 {
    font-size: 20px;
  }

  .article-wp h3 {
    font-size: 17px;
  }

  .article-wp h2,
  .article-wp h3,
  .article-wp ol,
  .article-wp ul {
    margin: 20px 0;
  }

  .article-wp p {
    font-size: 14px;
  }
  @include media-breakpoint-down(sm) {
    .article-wp h2 {
      font-size: 18px;
    }

    .article-wp h3 {
      font-size: 15px;
    }
  }
}
