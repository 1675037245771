// General :: Remove autofill (Chrome)

input:-webkit-autofill,
input:-webkit-autofill:focus input:-webkit-autofill,
input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:focus,
select:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus {
	border: none !important;
	-webkit-text-fill-color: inherit !important;
	-webkit-box-shadow: 0 0 0 1000px #FFFFFF inset;
	transition: background-color 5000s ease-in-out 0s;
}
// Input :: Text
.form-control {
	color: $bk-a !important;
	border-color: $bk-a;
	box-shadow: none;
	border-radius: 0;
	-webkit-appearance: none;
	-moz-appearance: none;

	&:focus {
		border-color: $bk-a;
		box-shadow: none;
		color: $bk-a;
		background: $wh-a;
	}
	outline: none!important;

	&:focus,
	&:hover {
		outline: none!important;
	}
}
// Input :: Text Placeholder

.form-control::placeholder {
	color: $gy-c;
	font-size: px(13);
	font-style: italic;
}
// Input :: Checkbox & Radio

.checkboxradio-wp {
	position: relative;
	display: block;

	> input,
	> label {
		cursor: pointer;
	}

	> input {
		position: absolute;
		-moz-opacity: 0;
		opacity: 0;
		filter: alpha(opacity = 0);
	}

	> input[type=radio] + label,
	> input[type=checkbox] + label {
		display: block;
		padding: 0 0 0 35px;
		font-size: rem(13);
		font-weight: 300;
		color: $bk-a;
		background-image: url("../images/icons/sprite-checkbox_radio.png");
		line-height: 1.3em;
		background-repeat: no-repeat;
		background-size: 17px;
	}

	> input[type=radio] + label {
		background-position: 0 -168px;
	}

	> input[type=checkbox] + label {
		background-position: 0 -537px;
	}

	> input[type=radio]:checked + label {
		background-position: 0 0;
	}

	> input[type=checkbox]:checked + label {
		background-position: 0 -352px;
	}
}

// Input :: Datepicker

.datepicker-wp {
	position: relative;
	height: px(33);


	&.open {
		.ui-datepicker-trigger {
			transform: rotate(180deg) translateY(10px);
		}
	}

	.form-control {
		outline: none !important;
		padding-right: px(30);
		&:focus,
		&:hover {
			outline: none!important;
		}
	}

	.ui-datepicker-trigger {
		position: absolute;
		top: px(12);
		right: px(5);
	}
}

.ui-datepicker {
	font-size: px(14);
	background-image: none !important;
	border-radius: 0 !important;

	.ui-widget-header {
		background: tint($bl-a,70%) !important;
		border-radius: 0 !important;
		border: none !important;
	}

	.ui-button,
	.ui-state-default,
	.ui-widget-content .ui-state-default,
	.ui-widget-header .ui-state-default {
		border: none;
		background: tint($bk-a,95%);
	}

	.ui-datepicker-today .ui-state-highlight {
		border: none;
		background: tint($bl-a,70%);
		color: $bl-b;
	}

	.ui-button.ui-state-active:hover,
	.ui-button:active,
	.ui-state-active,
	.ui-widget-content .ui-state-active,
	.ui-widget-header .ui-state-active,
	a.ui-button:active {
		border: none;
		background: $bl-a;
		color: $wh-a;
		font-weight: 700;
	}

	.ui-button:focus,
	.ui-button:hover,
	.ui-state-focus,
	.ui-state-hover,
	.ui-widget-content .ui-state-focus,
	.ui-widget-content .ui-state-hover,
	.ui-widget-header .ui-state-focus,
	.ui-widget-header .ui-state-hover {
	}
}
// Input :: Select Structure

.selectpicker-wp:not(.except-quantity) {
	position: relative;

	.bootstrap-select {
		width: 100% !important;
	}

	.filter-option {
		@include ellipsis;
	}

	.dropdown-toggle {
		display: flex;
		align-items: center;
		width: 100%;
		padding-right: px(30) !important;
		@extend .form-control;
		outline: none;
	}

	.dropdown-toggle:focus {
		outline: none !important;
	}

	.dropdown-toggle:after {
		@include position(absolute, 50% 10px null null);
		margin-top: -3px;
		color: $bk-a;
	}

	.bootstrap-select.show .dropdown-toggle:after {
		transform: rotate(-180deg);
	}
}
// Input :: Select Dropdown

.bootstrap-select.btn-group .dropdown-menu.inner {
	display: block;
}

.bootstrap-select.btn-group .dropdown-menu {
	padding: 0;

	li a,
	li a span.text {
		display: block;
	}

	li a {
		display: block;
		padding: px(5) px(15);
	}

	li a {
		outline: none !important;
	}

	li:not(:last-child) {
		border-bottom: 1px solid #f8f9fa;
	}

	li a:focus,
	li a:hover {
		background-color: #f8f9fa;
	}
}

.bootstrap-select .dropdown-menu .inner {
	max-height: 200px !important;
}

.bootstrap-select .dropdown-menu {
	min-width: 100% !important;
}

.dropdown-item {
	padding: px(5) px(10) !important;
}

.bootstrap-select .dropdown-menu li a {
	font-size: px(12);
}

.dropdown-item.active,
.dropdown-item:active {
	background-color: $bl-a;
}
// Validation :: Error
.parsley-errors-list {
	color: #a94442;
	font-size: px(12);
	margin-top: px(5);
}

.custom-file-input.parsley-error + .custom-file-label,
.form-control.parsley-error {
	border-color: #a94442;
	color: #a94442 !important;
}

.form-control.parsley-error::placeholder {
	color: #a94442;
}



.section-form-wp {
  padding: 25px;
  background-color: $wh-a;
  box-shadow: 0 0 20px rgba(0,0,0,0.1);
  border-radius: 8px;

  .label-bis {
    font-size: px(13);
    color: $bl-b;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  .form-control-bis {
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid shade($gy-a, 10%);
    color: $bk-a;
    font-size: px(13);
    padding: 6px 0;
    outline: none !important;
    -webkit-appearance: none;
    border-radius: 0 !important;
  }

  .password {
    display: block;
    margin-top: px(8);
    font-size: px(12);
    font-style: italic;
    text-decoration: underline;
  }

  textarea.form-control-bis {
    margin-top: 5px;
  }

  .dropdown.bootstrap-select {
    display: block;
    width: 100% !important;
    padding: px(6) 0;
    border-bottom: 1px solid shade($gy-a, 10%);

    .filter-option-inner {
      font-style: italic;
      color: shade($gy-a, 40%) !important;
      font-size: px(13);
    }

    &.changed {
      border-bottom-color: $gy-a;

      .filter-option-inner {
        font-style: normal;
        color: $gy-a !important;
        font-size: px(13);
      }
    }
  }

  .form-control-bis.active,
  .form-control-bis:focus {
    border-bottom-color: $bl-b;
  }

  .form-control-bis::placeholder {
    color: shade($gy-a, 40%) !important;
    font-style: italic;
    opacity: 1;
    font-size: px(13);
  }
  @include media-breakpoint-down(sm) {
    padding: 20px 20px 25px !important;
  }
}

.checkboxradio-wp {
  position: relative;
  display: block;

  > input,
  > label {
    cursor: pointer;
  }

  > input {
    position: absolute;
    -moz-opacity: 0;
    opacity: 0;
    filter: alpha(opacity = 0);
  }

  > input[type=radio] + label,
  > input[type=checkbox] + label {
    height: 50px;
    display: block;
    padding: 2px 0 0 60px;
    font-size: px(13);
    font-weight: 300;
    color: $bk-a;
    background-image: url("../images/icons/sprite-checkbox_radio-step1.png");
    line-height: 1.3em;
    background-repeat: no-repeat;
    background-size: 50px;
  }

  > input[type=radio] + label {
    background-position: 0 top;
  }

  > input[type=radio]:checked + label {
    background-position: 0 bottom;
  }
}
