#resa-header {
  position: relative;
  z-index: 10;
  width: 100%;
  background-color: $wh-a;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  transition: all 0.2s ease-in-out;
  padding: px(10) 0;

  .logo {
    position: relative;
    display: block;
    padding: 0;
    margin-top: px(-4);
    width: px(150);
  }

  .logo .img-fluid {
    display: block;
    margin: 0 auto;
  }

  .logo:after {
    content: "";
    position: absolute;
    top: -10px;
    right: -20px;
    height: calc(100% + 20px);
    width: 1px;
    background-color: tint($gy-b, 40%);
  }

  .item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $bl-b;
  }

  .item .txt {
    font-size: px(15);
    font-weight: 500;
  }

  .item .number {
    font-size: px(18);
    line-height: 1em;
    position: relative;
    top: -1px;
    font-weight: 400;
    margin-right: 5px;
  }

  .item .number:after {
    content: ".";
  }

  .item:not(.first):before {
    content: "";
    position: absolute;
    top: 50%;
    left: -15px;
    height: 14px;
    margin-top: -7px;
    width: 1px;
    background-color: tint($gy-b, 40%);
  }

  .item.active {
    color: $bl-b;
  }

  .item.active .number:after {
    display: none;
  }

  .item.active .number {
    background-color: $bl-b;
    margin-right: 10px;
    font-size: px(14);
    padding-top: 5px;
    color: $wh-a;
    text-align: center;
    border-radius: 25px;
    width: 25px;
    height: 25px;
  }

  .item.active .txt {
    font-weight: 700;
  }
  @include media-breakpoint-down(md) {
    padding: px(5) 0;

    .logo {
      width: px(120);
      padding: px(5) 0;

      img {
        margin: 0 !important;
      }
    }

    .logo:after {
      right: -15px;
    }

    .item .txt {
      font-size: px(13);
    }

    .item .number {
      font-size: px(14);
    }

    .item.active .number {
      font-size: px(11);
      width: 17px;
      height: 17px;
      padding-top: 4px;
    }
  }
  @include media-breakpoint-down(sm) {


    .row {
      align-items: flex-start !important;
    }

    .logo {
      padding: px(5) 0 0;
      margin: 0;
      width: px(110);
    }

    .logo:after {
      right: -20px;
      height: px(62);
    }

    .item {
      flex-flow: column wrap;
      padding-top: 8px;
    }

    .item .number {
      line-height: 1em;
      margin-right: 0 !important;
    }

    .item .number:after {
      display: none;
    }

    .item .txt {
      font-size: px(12);
    }

    .item.active .number {
      font-size: px(10);
      width: 15px;
      height: 15px;
      padding-top: 2px;
      line-height: 1em;
    }

    .item.active .txt {
      margin-top: 2px;
    }

    .item:not(.active) .txt {
      padding-top: 2px;
    }
  }
  @include media-breakpoint-down(xs) {
    padding: px(2) 0;

    .logo {
      width: px(90);
      padding-top: px(5);
      padding-bottom: px(5);

      img {
        position: relative;
        left: px(-5);
      }

      &:after {
        right: px(-10);
      }
    }

    .col-4 {
      flex: 0 0 90px;
      max-width: 90px;
    }

    .col-8 {
      flex: 0 0 calc(100% - 90px);
      max-width: calc(100% - 90px);
    }

    .row {
      align-items: center !important;
    }

    .item {
      padding-top: 0;
    }

    .item .txt {
      display: none;
    }

    .item.active .number {
      width: 20px;
      height: 20px;
      padding-top:px(5);
      font-size: px(12);
    }
  }
}

#resa-summary-wp {
  overflow: hidden;
  position: relative;
  padding: 30px 0;
  background-image: url("../images/illustrations/resa-bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @include media-breakpoint-down(md) {
    padding: 30px 0;
  }
  @include media-breakpoint-down(xs) {
    padding: 15px 0 15px 0 !important;
  }
}

#resa-summary {
  position: relative;
  z-index: 2;
  display: table;
  width: 100%;
  margin: 0 auto;
  background-color: $wh-a;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.15);

  .column {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    width: 41%;
    padding: 20px 15px 20px 0;

    &:not(.except):after {
      content: "";
      display: block;
      position: absolute;
      height: 100%;
      width: 1px;
      background-color: tint($gy-b, 40%);
      right: px(20);
      top: 0;
    }
  }

  .column.except {
    width: 18%;
  }

  .column.except .list {
    border-right: none;
  }

  .column:first-child .list {
    padding-left: 50px;
  }

  .column:first-child .list:after {
    left: 20px;
  }

  .list {
    padding: 0 15px 0 40px;
    position: relative;
    height: 100%;
  }

  .column .material-icons {
    position: absolute;
    top: 50%;
    left: 15px;
    margin-top: -10px;
    color: $bl-b;
  }

  .column.except-bis .material-icons {
    left: 5px;
    color: $bl-b;
  }

  .column.except .list:after {
    display: none;
  }

  .column.except .list {
    padding-left: 40px;
  }

  .column.except .img {
    position: absolute;
    top: 50%;
    margin-top: -10px;
    left: 5px;
    font-size: px(18);
  }

  .place {
    text-transform: uppercase;
    display: block;
    line-height: 1.2em;
    font-weight: 600;
    color: $bl-b;
    font-size: px(14);
  }

  .date {
    color: $gy-c;
    font-weight: 400;
    font-size: px(13);
    font-style: italic;
    display: block;
    line-height: 1.2em;
    margin-top: 5px;
  }

  .btn-submit {
    width: 130px;
    height: 84px;
    background-color: $ye-b;
    color: $wh-a;
    text-transform: uppercase;
    font-size: px(13);
    font-weight: 500;

    .material-icons {
      font-size: px(18);
      margin-right: px(5);
    }

    &.open {
      .material-icons {
        transform: rotate(180deg);
      }
    }
  }

  .btn-submit > * {
    display: inline-block;
    vertical-align: middle;
  }
  @include media-breakpoint-down(lg) {
    .column:not(.except):after {
      right: 15px;
    }
  }
  @include media-breakpoint-down(md) {
    .column {
      padding: 15px 15px 15px 0;
    }

    .place {
      font-size: px(12);
    }

    .date {
      font-size: px(11);
    }

    .column:not(.except):after {
      right: 5px;
    }

    .btn-submit {
      height: 81px;
      width: 120px;
    }
  }
  @include media-breakpoint-down(sm) {
    .place {
      font-size: px(11);
    }

    .date {
      font-size: px(11);
      margin-top: px(2);
    }

    .column {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    .column.except .img {
      display: none !important;
    }

    .column:first-child .list {
      padding-left: 15px !important;
    }

    .column .list {
      padding-left: 10px !important;
    }

    .column .material-icons {
      display: none !important;
    }

    .btn-submit {
      height: 70px;
      font-size: px(12);
      width: 90px;
      .material-icons {
        display: none;
      }
    }
  }
  @include media-breakpoint-down(xs) {
    .column.except .img {
      left: 8px !important;
      font-size: px(15) !important;
      display: block !important;
    }

    .column:after {
      display: none !important;
    }

    .column .material-icons {
      left: 7px !important;
      font-size: px(20) !important;
      display: block !important;
    }

    .column,
    .column .list {
      display: block;
      width: 100% !important;
    }

    .column .list,
    .column:first-child .list {
      border-right: none !important;
      padding-left: 32px !important;
    }

    .place {
      font-size: px(12);
    }

    .date {
      font-size: px(11);
    }

    .column {
      border-bottom: 1px solid tint($gy-b, 40);
      padding-top: 8px;
      padding-bottom: 8px;
    }

    .column.except {
      border-bottom: none !important;
    }

    .list:after {
      left: 10px !important;
    }

    .column.except .list:after {
      left: 10px !important;
    }

    .btn-submit {
      height: 35px;
      width: 100%;
      .material-icons {
        display: inline-block;
      }
    }
  }
}


#resa-inner {
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  &.closed {
    display: none;
  }
  .btn-close {
    position: fixed;
    color: $bl-b;
    font-size: px(50);
    line-height: 1em;
    top: px(0);
    right: px(0);
    opacity: 1;
    font-weight: 400;
    background-color: $wh-a;
    width: px(70);
    height: px(70);
  }
  @include media-breakpoint-down(xs) {
    padding: 0 px(15);
    .btn-close {
      width: px(40);
      height: px(40);
      font-size: px(30);
    }
  }
}

.resa-wp {
  position: relative;
  z-index: 10;
  .form-control {
  	color: $bk-a !important;
  	border: none;
    padding: 0;
    font-size: px(13);
    border-radius: 0;
  	border-bottom: 1px solid $gy-b;
    line-height: 1em;

  }
  .selectpicker-wp {
    .dropdown-toggle {
      height: px(34);
      background-color: $wh-a;
      line-height: 1em;
      &:after {
        border: none;
        @extend .sprite;
        @extend .arrow-select;
      }
      .filter-option-inner-inner {
        padding-top: px(9) !important;
        height: px(30);
        line-height: 1em;
      }
    }
  }
}

.resa-step-1 {
  position: relative;
  height: 100%;

  &.unavailable .btn-wp:after,
  &.unavailable:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.5);
    z-index: 4;
  }

  &.unavailable .col-bottom {
    border-top: none;
  }

  .content-item {
    height: 100%;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    background-color: $wh-a;
    box-shadow: 0 0 20px rgba(0,0,0,0.1);
    border-radius: 8px;
    overflow: hidden;
  }

  .col-top {
    background-color: $wh-a;
  }

  .img-wp {
    padding: 15px 20px 0;
    border-bottom: 1px solid shade($gy-a, 10%);
    position: relative;

    .btn-popup {
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: $gy-a;
      width: px(30);
      height: px(30);
      text-align: center;
      padding-top: px(4);

      .material-icons {
        font-size: px(20);
      }
    }
  }

  .category {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: px(10);
    font-weight: 600;
    text-transform: uppercase;
    color: $gy-c;
  }

  .discount {
    position: absolute;
    top: 18px;
    right: 25px;
    color: $wh-a;
    background-color: $bl-a;
    font-weight: 400;
    letter-spacing: 0.05em;
    font-size: px(10);
    padding: 2px 6px;
    border-radius: 3px;
  }

  .desc {
    font-size: px(11);
  }

  .content-txt {
    padding: 20px;
  }

  .title-car {
    font-size: px(15);
    color: $bl-b;
    line-height: 1.3em;
    font-weight: 600;
    letter-spacing: 0.025em;
    margin-bottom: 10px;
  }

  .title-car em {
    font-size: 85%;
    font-weight: 400;
  }

  .car-icon {
    display: flex;
    align-items: center;
    font-size: px(11);
    color: $gy-c;
    margin-top: 2px;
  }

  .car-icon .txt {
    white-space: nowrap;
    padding-left: px(3);
  }

  .col-bottom {
    background-color: $wh-a;
    border-left: 1px solid tint($gy-b, 50%);
    border-right: 1px solid tint($gy-b, 50%);
    border-bottom: 1px solid tint($gy-b, 50%);
    border-radius: 0 0 8px 8px;
    overflow: hidden;
  }

  .col-bottom .row {
    margin-left: 0;
    margin-right: 0;
  }

  .col-bottom .item {
    height: px(51);
    background-color: $gy-a;
    border-top: 1px solid shade($gy-a, 10%);
  }

  .col-bottom .item.except {}

  .btn-popover {
    outline: none !important;
    position: absolute;
    top: px(23);
    right: px(10);
    z-index: 10;
    margin-top: px(-8);
    color: $bl-b;

    .material-icons {
      font-size: 21px;
    }
  }
  .checkboxradio-wp {
    height: 100%;
    position: relative;

  }

  .col-bottom .item {
    position: relative;
  }

  .col-bottom .checkboxradio-wp label {
    font-size: px(11);
    text-transform: uppercase;
    font-weight: 400 !important;
    text-align: left;
    padding-top: px(16);
  }

  .col-bottom .checkboxradio-wp .txt {
    font-size: px(11);
    display: block;
  }

  .checkboxradio-wp .price {
    font-weight: 600 !important;
    color: $bl-b;
    font-size: px(15);
    display: inline-block;
    margin-top: 0;
  }

  .btn-wp {
    position: absolute;
    bottom: -60px;
    left: 0;
    width: 100%;
  }

  .btn-resa {
    @extend .btn-a;
    @extend .yellow;
    text-transform: uppercase;
    font-weight: 600;
    padding: px(15);
  }

  .btn-resa .material-icons {
    font-size: px(16);
    vertical-align: middle;
    margin-left: 10px;
    position: relative;
    margin-top: -3px;
  }


  .date-unavailable {
    position: relative;
    z-index: 99;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    width: 100%;
    border-top: 1px solid shade($gy-a, 10%);
    padding: 15px;
    height: px(100);
    text-align: center;
    background-color: $gy-a;
    font-size: px(13);
  }

  .date-unavailable strong {
    color: $bl-b;
  }

  .item-except-price {
    background-color: $gy-a;
    padding: 13px 15px;
    text-align: center;
    font-size: px(11);
    border-top: 1px solid shade($gy-a, 10%);
  }

  .item-except-price strong {
    font-weight: 600;
    color: $bl-a;
    font-size: px(16);
    margin: 0 2px;
  }

  .item-except-price em {
    font-weight: 400;
    font-size: px(12);
  }
  @include media-breakpoint-down(lg) {
    .car-icon {
      font-size: px(9);
    }
  }
  @include media-breakpoint-down(md) {
    .car-icon {
      font-size: px(11);
    }
  }
  @include media-breakpoint-down(sm) {
    .content-txt {
      padding: px(15);
    }

    .category {
      left: px(15);
      top: px(15);
      font-size: px(9);
    }

    .discount {
      right: px(15);
      top: px(15);
      font-size: px(9);
    }

    .img-wp {
      padding: 15px 15px 0;
    }

    .title-car {
      font-size: px(13.5);
    }

    .col-bottom .checkboxradio-wp .txt {
      font-size: px(10);
    }

    .col-bottom .checkboxradio-wp .price {
      font-size: px(13.5);
    }
  }
}

.resa-step-2 {
  .item {
    border-bottom: 1px solid shade($gy-a,10%);
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 75px;
    font-size: px(13);
    margin-bottom: 10px;
    display: table;
    background-color: $gy-a;

    &.price-off {
      @include media-breakpoint-down(xs) {
        padding-bottom: 8px !important;

        &:after {
          display: none !important;
        }
      }
    }

    &.total {
      margin-top: px(30);
      background-color: $bl-b;
      border-bottom: 1px solid $gy-b;
      padding: px(8);

      &:after {
        display: none !important;
      }

      .title {
        color: $wh-a;
        text-transform: uppercase;
        font-weight: 500;
        padding-left: px(10);
      }

      .col-option-2 {
        border-radius: 5px;
        border: none;
        font-size: px(20);
        color: $bl-b;
        background-color: $wh-a;
        font-weight: 700;

        &:before {
          display: none;
        }
      }
      @include media-breakpoint-down(xs) {
        margin-top: px(20);

        .col-option-1 {
          padding-left: 0 !important;

          .title {
            font-size: px(15);
          }
        }

        .col-option-2 {
          width: 110px !important;
          height: 57px !important;
          padding-top: px(0);

          .price {
            font-size: 18px;
          }
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    .more-info-trigger {
      position: absolute;
      top: 50%;
      right: 10px;
      z-index: 2;
      margin-top: -9px;
      color: $bl-b;
      outline: none;

      .material-icons {
        font-size: 21px;
      }
    }

    .col-option-1,
    .col-option-2 {
      display: table-cell;
      padding: 0 15px;
      vertical-align: middle;
    }

    .col-option-1 {
      padding: 8px 35px 8px 8px;
      font-weight: 700;
    }

    .checkboxradio-wp {
      display: table;
      width: 100%;

      > input[type=checkbox] + label {
        font-size: px(14);
        height: 56px;
        width: 100%;
        display: table-cell;
        font-weight: 500;
        color: $gy-c;
        line-height: 1.15em;
        margin-bottom: 0;
        background-image: url("../images/icons/sprite-checkbox_radio-step2_3.png");
        vertical-align: middle;
        padding: 0 0 0 70px;
        background-size: 56px;

        em {
          display: block;
          font-weight: 300;
          font-size: 85%;
          color: $gy-c !important;
          margin-top: px(3);
        }
        @include media-breakpoint-down(lg) {
          font-size: px(13);
        }
        @include media-breakpoint-down(sm) {
          font-size: px(15);
        }
        @include media-breakpoint-down(xs) {
          font-size: px(14);
        }
      }

      > input[type=checkbox] + label {
        background-position: 0 bottom;
      }

      > input[type=checkbox]:checked + label {
        background-position: 0 top;
        font-weight: 600;
        color: $bl-b;
      }
    }
  }

  .quantite, .choix {
    padding: 0 0 0 70px;

    .selectpicker-wp {
      display: inline-block;

      .bootstrap-select .btn.dropdown-toggle {
        border-radius: 6px;
        padding: 5px 10px !important;
        border: $wh-a !important;
        background-color: $wh-a;

        .filter-option-inner-inner {
          font-size: px(14);
          text-align: center;
        }
      }

    }
    .label {
      font-weight: normal;
      padding-right: 10px;
    }
    &.disabled .selectpicker-wp .bootstrap-select .btn.dropdown-toggle,
    &.disabled .label {
      opacity: 0.4;
    }
  }
}

.step-3-input {
  background-color: $gy-a;
  padding: px(10) px(15);
  border-radius: 8px;
  border-bottom: 1px solid shade($gy-a, 10%);

  label {
    text-transform: uppercase;
    font-weight: 600;
    color: $bl-b;
    font-size: px(11);
  }

  input,
  textarea,
  .selectpicker-wp .dropdown-toggle {
    outline: none !important;
    box-shadow: none !important;
    border: none;
  }
}

.resa-step-4 {
  position: relative;
  height: 100%;
  padding: 25px 30px;
  background-color: $wh-a;
  box-shadow: 0 0 20px rgba(0,0,0,0.1);
  border-radius: 8px;

  .title-sub {
    color: $bl-b;
    text-transform: uppercase;
    font-size: px(16);
    font-weight: 600;
    border-bottom: 1px solid $bl-b;
    padding-bottom: 5px;
  }

  .list {
    margin-top: 20px;
  }

  .list li {
    display: block;
    position: relative;
    padding-left: 18px;
    font-size: px(14);
    line-height: 1.4em;
    word-break: break-word;
  }

  .list li:not(:last-child) {
    margin-bottom: 7px;
  }

  .list li:before {
    content: "";
    position: absolute;
    top: 9px;
    left: 0;
    width: 8px;
    height: 2px;
    background-color: $bl-b;
  }
}
@include media-breakpoint-up(sm) {
  .tooltip {
    display: none !important;
  }
}

.resa-aside {
  border-radius: 10px;
  overflow: hidden;
  position: relative;

  .header {
    background-color: $bl-b;
    position: relative;
    color: $wh-a;
    padding: px(13) px(70) px(13) px(15);

    .category {
      text-transform: uppercase;
      font-size: px(10);
      letter-spacing: 0.1em;
      font-weight: 300;
    }

    .title {
      margin-top: px(3);
      font-size: px(17);
      font-weight: 400;
      line-height: 1.1em;

      em {
        font-size: 80%;
        font-weight: 300;
      }
    }

    .btn-edition {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: px(55);
      background-color: rgba(0,0,0,0.3);
      color: $wh-a;
      padding-top: px(7);

      .material-icons {
        font-size: px(26);
      }
    }
  }

  .car-wp {
    padding-bottom: px(15);

    .icons-wp {
      padding: 0 px(20);

      .txt {
        font-size: px(10);
        display: block;
        line-height: 1.1em;
      }

      .car-icon {
        position: relative;
        padding: px(5) 0 px(5) px(17);

        .sprite {
          position: absolute;
          top: px(-1);
          left: px(-5);
        }
      }
    }
  }

  .options-wp {
    padding: px(10) px(20);
    background-color: $gy-a;

    .item {
      position: relative;
      padding: px(9) px(80) px(9) 0;
      font-size: px(13);
      font-weight: 500;
      color: shade($gy-c, 40%);
      font-weight: 400;
      line-height: 1.2em;

      .price {
        position: absolute;
        top: px(9);
        right: 0;
        font-weight: 500;
      }

      &.promo {
        position: relative;
        padding: 5px;
        background-color: rgba(0,0,0,0.05);
        border-radius: 5px;
        border: 1px solid shade($gy-b, 10%);
        margin-bottom: px(10);
        line-height: 1.2em;

        .btn-promo {
          text-align: left;
          position: relative;
          display: block;
          width: 100%;
          font-style: italic;
          font-size: px(12);
          color: $bl-b;
          font-weight: 600;
          padding-left: px(20);

          .material-icons {
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: -8px;
            font-size: px(15);
          }
        }
      }

      &.except {
        font-weight: 600;
        text-transform: uppercase;
        color: $bl-b;

        .price {
          font-weight: 600;
        }
      }
    }

    .item:not(:first-child) {
      border-top: 1px solid tint($gy-b, 40%);
    }

    &.step-3 {
      .price {
        font-weight: 400 !important;
        text-decoration: underline;
        text-transform: none;
        font-size: px(11);
      }

      .item:not(.except) {
        padding-right: 0;

        em {
          font-size: 95%;
          font-style: italic;
        }
      }
    }

    .txt-info {
      padding: px(0) 0 0;
      font-style: italic;
      line-height: 1.2em;
      color: $bl-b;
      font-size: px(11);
      margin-top: px(-5);
      margin-bottom: px(8);
    }
  }

  .btn-wp {
    display: block;
    width: 100%;
    text-transform: uppercase;
    font-size: px(16);
    padding: px(13);
    font-weight: 600;
    background-color: $ye-b;
    color: $wh-a;
    text-align: center;
    letter-spacing: 0.05em;

    > * {
      display: inline-block;
      vertical-align: middle;
    }
  }
  @include media-breakpoint-down(md) {
    .header {
      padding-top: px(9);
      padding-bottom: px(9);

      .title {
        margin-top: 0;
      }
    }

    .content-wp {
      position: relative;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      border: 1px solid $gy-a;
      border-radius: 0 0 8px 8px;

      .car-wp {
        flex: 0 0 45%;
        max-width: 45%;
        padding: 0 px(15) px(10);
      }

      .options-wp {
        flex: 0 0 55%;
        max-width: 55%;
        position: relative;
        z-index: 2;
        padding: px(10) px(25);

        .item {
          .price {
            top: px(9);
          }
        }
      }

      &:after {
        content: "";
        width: 55%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background-color: $gy-a;
      }
    }

    .btn-wp {
      display: block;
      width: 240px;
      margin: px(15) auto 0;
      border-radius: 8px;
      padding-top: px(8);
      padding-bottom: px(8);
      font-size: px(14);
    }
  }
  @include media-breakpoint-down(xs) {
    .content-wp {
      display: block;

      .car-wp,
      .options-wp {
        display: block;
        width: 100%;
        max-width: 100%;
      }

      &:after {
        display: none;
      }
    }
  }
}

.resa-included {
  border-bottom: 1px solid shade($gy-a, 10%);
  padding: 15px;
  background-color: $gy-a;
  border-radius: 8px;

  .item {
    line-height: 1.2em;
    position: relative;
    padding: px(3) 0 0 px(25);
    margin-bottom: px(5);
    font-size: px(14);
    font-weight: 500;
    color: $bl-b;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .item .material-icons {
    content: "";
    position: absolute;
    color: $bl-a;
    top: px(3);
    left: 0;
    font-size: px(18);
  }
}

.btn-cgv {
  width: 100%;
  color: $gy-c !important;
  text-align: center;
  display: block;
  padding: px(10) 0;
  font-size: px(12);
  text-decoration: underline !important;
}

.popover-header {
  font-weight: 700;
  color: $bl-b;
}

.btn-payment {
  position: relative;
  background-color: $ye-b;
  color: $wh-a;
  border-radius: 10px;
  overflow: hidden;
  text-transform: uppercase;
  padding: px(10) px(20) px(10) px(75);

  .icon-left {
    position: absolute;
    height: 100%;
    width: 50px;
    padding-top: px(12);
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.1);
  }

  .icon-right,
  .txt {
    display: inline-block;
    vertical-align: middle;
  }

  .icon-right {
    position: relative;
    top: 3px;
  }
}
